<template>
  <b-container class="py-bw-5">
    <b-row class="mb-4">
      <b-col cols="12">
        <p class="p-bw-bold mb-2">
          {{ $t("home.section3.subtitle") }}
        </p>
        <h2 class="h2-bw">
          {{ $t("home.section3.title") }}
        </h2>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col class="">
        <!-- Fitogram Booking Tool starts here -->
        <div id="fitogram-booking-tool"></div>
        <!-- Fitogram Booking Tool ends here -->
        <b-button
          variant="dark"
          class="w-25 mt-5 mx-auto hide-mobile"
          href="https://widget.fitogram.pro/barliner-workout/?w=/list-view"
          target="_blank"
        >
          {{ $t("home.section3.buttonCourseSchedule") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Fitogram',
  components: {},
  data () {
    return {
      fitogram: null
    }
  },
  mounted () {
    this.$loadScript('https://widget.fitogram.pro/widget.min.js')
      .then(() => {
        this.fitogram = window.Widget
        this.fitogram.default.config({
          domain: 'barliner-workout',
          loginTarget: 'calendar',
          selector: '#fitogram-booking-tool'
        })
        this.fitogram.default.calendar.new().render()
      })
      .catch(() => {
        console.log('Falied to load fitogram widget')
      })
  }
}
</script>

<style lang="scss" scoped>

</style>
